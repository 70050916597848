<template>
  <div>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-w="3" vs-type="flex" vs-justify="center">
        <label for>Profile Image</label>
      </vs-col>
    </vs-row>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-w="3" vs-type="flex" vs-justify="center">
        <a
          id="profileImage"
          v-if="userDetail.profileImage"
          :href="userDetail.profileImage"
          target="_blank"
        >
          <div class="img-wrapper">
            <img :src="userDetail.profileImage" class="img-fluid" alt />
            <!-- <vs-avatar size="110px" :src="userDetail.profileImage" /> -->
          </div>
        </a>
        <vs-avatar
          style="cursor: pointer"
          @click="open_in_new_tab(defaultPP)"
          size="110px"
          :src="defaultPP"
          v-else
        />
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col
        id="profileImageUpload"
        class="vs-con-loading__container"
        vs-w="3"
      >
        <div class="m-3" style="text-align: center; position: relative">
          <label
            class="image-upload profile-image-upload"
            style="
              width: 60%;
              border: 1px solid #3dc9b3;
              display: inline-block;
              border-radius: 25px;
              padding: 5px;
            "
          >
            Change Photo
            <input
              type="file"
              accept="image/*"
              @change="
                uploadImage(
                  $event,
                  'profileImageUpload',
                  'profile-image-upload'
                )
              "
              id="file-input"
              hidden
            />
          </label>
        </div>
      </vs-col>
    </vs-row>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('firstName') ? true : false"
            :success="!errors.first('firstName') && userDetail.firstName !== ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="first name"
            name="firstName"
            label-placeholder="First Name"
            placeholder="First Name"
            v-model="userDetail.firstName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("firstName")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('lastName') ? true : false"
            :success="!errors.first('lastName') && userDetail.lastName !== ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="last name"
            name="lastName"
            label-placeholder="Last Name"
            placeholder="Last Name"
            v-model="userDetail.lastName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("lastName")
          }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('companyName') ? true : false"
            :success="
              !errors.first('companyName') && userDetail.companyName !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="company name"
            name="companyName"
            label-placeholder="Company Name"
            placeholder="Company Name"
            v-model="userDetail.companyName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("companyName")
          }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('clinicName') ? true : false"
            :success="
              !errors.first('clinicName') && userDetail.clinicName !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{ required: true }"
            data-vv-validate-on="blur"
            data-vv-as="clinic name"
            name="clinicName"
            label-placeholder="Clinic Name"
            placeholder="Clinic Name"
            v-model="userDetail.clinicName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("clinicName")
          }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('abn') ? true : false"
            :success="!errors.first('abn') && userDetail.abn !== ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="abn"
            name="abn"
            label-placeholder="ABN"
            placeholder="ABN"
            v-model="userDetail.abn"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{ errors.first("abn") }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <div :class="{ dateClass: !errors.has('abnCertificate') }">
            <label class="vs-input--label custom-label"
              >ABN Certificate (Please upload your ABN certificate, or a
              screenshot from https://abr.business.gov.au/)</label
            >
            <div style="margin-top: 3px">
              <div class="mb-4" v-if="userDetail.abnCertificate">
                <div
                  v-if="determineFileType(userDetail.abnCertificate) === 'pdf'"
                  style="margin: auto; width: 50%"
                >
                  <a :href="userDetail.abnCertificate" target="_blank"
                    >Click here to view file</a
                  >
                </div>
                <div v-else style="margin: auto" class="register-img-wrapper">
                  <a :href="userDetail.abnCertificate" target="_blank">
                    <img :src="userDetail.abnCertificate" class="img-fluid" />
                  </a>
                </div>
              </div>
              <div
                id="abnCertificateUpload"
                :class="{ 'mt-3': userDetail.abnCertificate }"
                style="text-align: center; position: relative"
              >
                <label
                  id="abnCertificateId"
                  :class="{ 'six-pixel': errors.has('abnCertificate') }"
                  class="
                    image-upload
                    abn-certificate-upload
                    vs-con-loading__container
                  "
                  style="
                    border: 1px solid #cccccc;
                    display: inline-block;
                    width: 100%;
                    border-radius: 5px;
                    padding: 10px;
                  "
                >
                  + Attach Document
                  <input
                    type="file"
                    @change="
                      uploadImage(
                        $event,
                        'abnCertificateUpload',
                        'abn-certificate-upload'
                      )
                    "
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
          </div>
          <span class="text-danger text-sm error-text">
            {{ errors.first("abnCertificate") }}
          </span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <ClinicPhotos
            :clinicPhotosField="userDetail.councilPermits"
            from="create"
            loadingClass="councilPermits"
            label="Please upload any required council permits (Optional)"
            popupTitle="Councli Permits"
            @removeImage="removeImage"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <ClinicPhotos
            :clinicPhotosField="userDetail.poisonsCertificate"
            from="create"
            loadingClass="poisonsCertificate"
            label="Please upload your poisons certificate (For clinics in VIC and WA) (Optional)"
            popupTitle="Poisons Certificate"
            @removeImage="removeImage"
          />
        </div>
      </vs-col>

      <vs-col
        style="padding: 17px"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div
          class="
            vs-component vs-con-input-label vs-input
            w-full
            mt-6
            vs-input-primary
            is-label-placeholder
          "
          :class="{
            'input-icon-validate-danger':
              errors.first('displayAddress') ||
              (addressObj.displayAddress && !addressObj.coordinates.latitude) ||
              checkDisplayAddress != addressObj.displayAddress,
            'input-icon-validate-success':
              addressObj.coordinates.latitude &&
              checkDisplayAddress == addressObj.displayAddress,
          }"
        >
          <div class="vs-con-input">
            <input
              type="text"
              placeholder
              ref="googleAutocomplete"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="clinic street address"
              name="displayAddress"
              v-model="addressObj.displayAddress"
              class="vs-inputx vs-input--input normal"
              :class="{ hasValue: addressObj.displayAddress }"
              style="border: 1px solid rgba(0, 0, 0, 0.2)"
            />
            <span
              class="
                input-span-placeholder
                vs-input--placeholder
                normal normal
                vs-placeholder-label
              "
              >Clinic Street Address</span
            >

            <span class="input-icon-validate vs-input--icon-validate">
              <i
                v-if="
                  addressObj.coordinates &&
                  addressObj.coordinates.latitude &&
                  checkDisplayAddress == addressObj.displayAddress
                "
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >done</i
              >
              <i
                v-else-if="
                  errors.first('displayAddress') ||
                  (addressObj.displayAddress &&
                    !addressObj.coordinates.latitude) ||
                  checkDisplayAddress != addressObj.displayAddress
                "
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >error</i
              >
            </span>
          </div>
          <span>
            <div class="con-text-validation vs-input--text-validation">
              <span
                class="
                  span-text-validation span-text-validation-success
                  vs-input--text-validation-span
                "
              ></span>
            </div>
          </span>
        </div>
        <span class="text-danger text-sm">
          {{ errors.first("displayAddress") }}
        </span>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            data-vv-scope="basic"
            :danger="errors.first('basic.clinicSuburb') ? true : false"
            :danger-text="errors.first('basic.clinicSuburb')"
            :success="
              !errors.first('basic.clinicSuburb') && addressObj.suburb != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-as="clinic suburb"
            name="clinicSuburb"
            label="Clinic Suburb"
            v-model="addressObj.suburb"
            class="w-full"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            data-vv-scope="basic"
            :danger-text="errors.first('basic.clinicPostCode')"
            :danger="errors.first('basic.clinicPostCode') ? true : false"
            :success="
              !errors.first('basic.clinicPostCode') && addressObj.postCode != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required|integer'"
            data-vv-as="clinic post code"
            name="clinicPostCode"
            label="Clinic Post Code"
            v-model="addressObj.postCode"
            class="w-full"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            data-vv-scope="basic"
            :danger-text="errors.first('basic.clinicState')"
            :danger="errors.first('basic.clinicState') ? true : false"
            :success="
              !errors.first('basic.clinicState') && addressObj.state != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-as="clinic state"
            name="clinicState"
            label="Clinic State"
            v-model="addressObj.state"
            class="w-full"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <div style="margin-top: 13px">
            <label
              style="display: block"
              class="vs-input--label custom-label mb-1"
            >
              Is this a residential address?
            </label>
            <vs-radio
              vs-value="Yes"
              vs-name="radios1"
              style="margin-right: 20px"
              v-model="userDetail.isResidentialAddress"
              >Yes</vs-radio
            >
            <vs-radio
              vs-value="No"
              vs-name="radios1"
              v-model="userDetail.isResidentialAddress"
              >No</vs-radio
            >
            <span
              v-if="validationKeys.showIsResidentialAddressError"
              style="display: block"
              class="text-danger text-sm error-text"
              >Please select one option.</span
            >
          </div>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            data-vv-scope="basic"
            :danger-text="errors.first('basic.clinicAddress1')"
            :danger="errors.first('basic.clinicAddress1') ? true : false"
            :success="
              !errors.first('basic.clinicAddress1') &&
              userDetail.clinicAddress1 != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            data-vv-as="Clinic Address 1"
            name="clinicAddress1"
            label="Clinic Address 1 (Optional)"
            v-model="userDetail.clinicAddress1"
            class="w-full"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('postalAddress') ? true : false"
            :success="
              !errors.first('postalAddress') && userDetail.postalAddress !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            data-vv-as="postal address"
            name="postalAddress"
            label-placeholder="Postal Address (optional)"
            placeholder="Postal Address (optional)"
            v-model="userDetail.postalAddress"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("postalAddress")
          }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('bestContactName') ? true : false"
            :success="
              !errors.first('bestContactName') &&
              userDetail.bestContactName !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{ required: true }"
            data-vv-validate-on="blur"
            data-vv-as="best contact name"
            name="bestContactName"
            label-placeholder="Best Contact Name"
            placeholder="Best Contact Name"
            v-model="userDetail.bestContactName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("bestContactName")
          }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('bestContactEmail') ? true : false"
            :success="
              !errors.first('bestContactEmail') &&
              userDetail.bestContactEmail !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required|email'"
            data-vv-validate-on="blur"
            data-vv-as="best contact email"
            name="bestContactEmail"
            label-placeholder="Best Contact Email"
            placeholder="Best Contact Email"
            v-model="userDetail.bestContactEmail"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("bestContactEmail")
          }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('bestContactNumber') ? true : false"
            :success="
              !errors.first('bestContactNumber') &&
              userDetail.bestContactNumber !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="best contact number"
            name="bestContactNumber"
            label-placeholder="Best Contact Number"
            placeholder="Best Contact Number"
            v-model="userDetail.bestContactNumber"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("bestContactNumber")
          }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('instagram') ? true : false"
            :success="!errors.first('instagram') && userDetail.instagram != ''"
            val-icon-success="done"
            val-icon-danger="error"
            data-vv-as="instagram"
            name="instagram"
            label="Instagram (Optional)"
            v-model="userDetail.instagram"
            class="w-full"
          />
          <span class="text-danger text-sm error-text">{{
            errors.first("instagram")
          }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('facebook') ? true : false"
            :success="!errors.first('facebook') && userDetail.facebook != ''"
            val-icon-success="done"
            val-icon-danger="error"
            data-vv-as="facebook"
            name="facebook"
            label="Facebook (Optional)"
            v-model="userDetail.facebook"
            class="w-full"
          />
          <span class="text-danger text-sm error-text">{{
            errors.first("facebook")
          }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('website') ? true : false"
            :success="!errors.first('website') && userDetail.website != ''"
            val-icon-success="done"
            val-icon-danger="error"
            data-vv-as="website"
            name="website"
            label="Website (Optional)"
            v-model="userDetail.website"
            class="w-full"
          />
          <span class="text-danger text-sm error-text">{{
            errors.first("website")
          }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('contactNumber') ? true : false"
            :success="
              !errors.first('contactNumber') && userDetail.contactNumber != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            name="phoneNumber"
            label="Phone Number (Optional)"
            v-model="userDetail.contactNumber"
            class="w-full"
          />
          <span class="text-danger text-sm error-text">{{
            errors.first("contactNumber")
          }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('invoiceEmail') ? true : false"
            :success="
              !errors.first('invoiceEmail') && userDetail.invoiceEmail !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required|email'"
            data-vv-validate-on="blur"
            data-vv-as="invoice email"
            name="invoiceEmail"
            label-placeholder="Invoice Email"
            placeholder="Invoice Email"
            v-model="userDetail.invoiceEmail"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("invoiceEmail")
          }}</span>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <div style="margin-top: 13px">
            <label
              style="display: block"
              class="vs-input--label custom-label mb-1"
            >
              Do you have adrenaline and hyalase in the clinic?
            </label>
            <vs-radio
              vs-value="Yes"
              vs-name="radios99"
              style="margin-right: 20px"
              v-model="userDetail.haveAdrenalineAndHyalaseInClinic"
              >Yes</vs-radio
            >
            <vs-radio
              vs-value="No"
              vs-name="radios99"
              v-model="userDetail.haveAdrenalineAndHyalaseInClinic"
              >No</vs-radio
            >
            <span
              v-if="validationKeys.showHaveAdrenalineAndHyalaseInClinicError"
              style="display: block"
              class="text-danger text-sm error-text"
              >Please select one option.</span
            >
          </div>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <div style="margin-top: 13px">
            <label
              style="display: block"
              class="vs-input--label custom-label mb-1"
            >
              Do you intend to secure S4 medication for your clinic through
              Fresh Clinics
            </label>
            <vs-radio
              vs-value="Yes"
              vs-name="radios999"
              style="margin-right: 20px"
              v-model="userDetail.intendToSecureS4Medication"
              >Yes</vs-radio
            >
            <vs-radio
              vs-value="No"
              vs-name="radios999"
              style="margin-right: 20px"
              v-model="userDetail.intendToSecureS4Medication"
              >No</vs-radio
            >
            <vs-radio
              vs-value="Unsure"
              vs-name="radios999"
              v-model="userDetail.intendToSecureS4Medication"
              >Unsure</vs-radio
            >
            <span
              v-if="validationKeys.showIntendToSecureS4MedicationError"
              style="display: block"
              class="text-danger text-sm error-text"
              >Please select one option.</span
            >
          </div>
        </div>
      </vs-col>

      <clinic-opening-hours
        v-if="this.userDetail.userType === 'clinic'"
        ref="stepTwoRef"
        :weekDaysData="userDetail.clinicOpeningHours"
        from="create"
      />

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <ClinicPhotos
            ref="stepThreeRef"
            :clinicPhotosField="userDetail.clinicFrontPhoto"
            from="create"
            loadingClass="clinicFrontPhoto"
            label="Upload Clinic Front Photos (Please upload a photo of the front of your clinic (from the street))"
            popupTitle="Clinic Front Photos"
            @removeImage="removeImage"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <ClinicPhotos
            ref="step4Ref"
            :clinicPhotosField="userDetail.clinicFloorPhoto"
            from="create"
            loadingClass="clinicFloorPhoto"
            label="Upload Clinic Floor Photos (Please upload photos of the floor of the treatment areas of your clinic)"
            popupTitle="Clinic Floor Photos"
            @removeImage="removeImage"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <ClinicPhotos
            ref="step5Ref"
            :clinicPhotosField="userDetail.clinicTreatmentBedPhoto"
            from="create"
            loadingClass="clinicTreatmentBedPhoto"
            label="Treatment bed (Please upload photos of the treatment beds in your clinic)"
            popupTitle="Clinic Treatment Bed Photos"
            @removeImage="removeImage"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <ClinicPhotos
            ref="step6Ref"
            :clinicPhotosField="userDetail.clinicDrawingUpAreaPhoto"
            from="create"
            loadingClass="clinicDrawingUpAreaPhoto"
            label="Drawing up area (Please upload photos of the drawing up areas in your treatment rooms. Please show gloves and hand-washing facilities, if available)"
            popupTitle="Clinic Drawing Up Area Photos"
            @removeImage="removeImage"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <ClinicPhotos
            ref="step7Ref"
            :clinicPhotosField="userDetail.clinicFridgePhoto"
            from="create"
            loadingClass="clinicFridgePhoto"
            label="Fridge (Please upload photos of the fridge for medication storage, including your temperature monitoring device. Show your lock if available)"
            popupTitle="Clinic Fridge Photos"
            @removeImage="removeImage"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <ClinicPhotos
            ref="step8Ref"
            :clinicPhotosField="userDetail.clinicDrugStoragePhoto"
            from="create"
            loadingClass="clinicDrugStoragePhoto"
            label="Drug Storage (Please upload photos of the cupboard where other medications will be stored. Show the ability to lock the cupboard, if possible.)"
            popupTitle="Clinic Drug Storage Photos"
            @removeImage="removeImage"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <ClinicPhotos
            ref="step9Ref"
            :clinicPhotosField="userDetail.clinicBinsPhoto"
            from="create"
            loadingClass="clinicBinsPhoto"
            label="Bins (Please show photos of your sharps bins and general storage bins.)"
            popupTitle="Clinic Bins Photos"
            @removeImage="removeImage"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <ClinicPhotos
            ref="step10Ref"
            :clinicPhotosField="userDetail.clinicRoomLockPhoto"
            from="create"
            loadingClass="clinicRoomLockPhoto"
            label="Room Lock (Please show photos of the locks on your treatment room, if available.) (Optional)"
            popupTitle="Clinic Room Lock Photos"
            @removeImage="removeImage"
          />
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-align="left"
        vs-w="6"
        v-if="this.userDetail.userType === 'clinic'"
      >
        <div class="w-full m-5">
          <ClinicPhotos
            ref="step11Ref"
            :clinicPhotosField="userDetail.clinicEpipenAndHyalasePhoto"
            from="create"
            loadingClass="clinicEpipenAndHyalasePhoto"
            label="Emergency Medication (Please upload photos of your epipen (or adrenaline) and hyalase. Please show expiry dates in the images. If you do not have these medications you may purchase them after signing up.) (Optional)"
            popupTitle="Clinic Emergency Medication Photos"
            @removeImage="removeImage"
          />
        </div>
      </vs-col>

      <vs-row>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-button
              @click="saveUserDetails"
              :disabled="!validateForm || disableSaveButton()"
              >Save Details</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Button from "../vuesax/button/Button";
import axios from "@/axios";
import { Validator } from "vee-validate";

import ClinicOpeningHours from "../clinics/ClinicOpeningHours.vue";
import ClinicPhotos from "../clinics/ClinicPhotos";

const dict = {
  custom: {
    bestContactNumber: {
      required: "Please enter your best contact number",
      regex: "Invalid Australian number",
    },
  },
};
Validator.localize("en", dict);

export default {
  name: "ClinicProfileEdit",
  inject: ["$validator"],
  components: {
    Button,
    ClinicOpeningHours,
    ClinicPhotos,
  },
  data() {
    return {
      validationKeys: {
        showIsResidentialAddressError: false,
        showIntendToSecureS4MedicationError: false,
        showHaveAdrenalineAndHyalaseInClinicError: false,
      },
      defaultPP: require("@/assets/images/profile/default.png"),
      userDetail: {
        id: "",
        firstName: "",
        lastName: "",
        userType: "",
        profileImage: "",
        address: "",
        companyName: "",
        clinicName: "",
        abn: "",
        postalAddress: "",
        bestContactName: "",
        bestContactEmail: "",
        billingEmail: "",
        invoiceEmail: "",
        userAddressObject: null,
        contactNumber: "",
        instagram: "",
        facebook: "",
        website: "",
        clinicAddress1: "",
        isResidentialAddress: "",
        haveAdrenalineAndHyalaseInClinic: "",
        intendToSecureS4Medication: "",
        abnCertificate: "",
        councilPermits: [],
        poisonsCertificate: [],
        clinicOpeningHours: {
          mondays: "",
          tuesdays: "",
          wednesdays: "",
          thursdays: "",
          fridays: "",
          saturdays: "",
          sundays: "",
        },
        clinicFrontPhoto: [],
        clinicFloorPhoto: [],
        clinicTreatmentBedPhoto: [],
        clinicDrawingUpAreaPhoto: [],
        clinicFridgePhoto: [],
        clinicDrugStoragePhoto: [],
        clinicBinsPhoto: [],
        clinicRoomLockPhoto: [],
        clinicEpipenAndHyalasePhoto: [],
        removedImages: [],
      },
      file: "",
      tempProfileImage: "",
      addressItems: [],
      addressObj: {
        displayAddress: "",
        suburb: "",
        state: "",
        postCode: "",
        coordinates: { latitude: null, longitude: null },
      },
      checkDisplayAddress: "",
      fetchedData: null,
      updater: null,
      oldData: null
    };
  },
  methods: {
    ...mapActions("general", [
      "updateClinicWebProfile",
      "deleteUploadedFile",
      "changeProfileImage",
      "updateAdminProfile",
      "removeClinicImages",
    ]),
    disableSaveButton() {
      if (this.$store.state.AppActiveUser.userType === "doctorClinic") {
        return true;
      }
      return false;
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },

    removeImage(imageUrl, selector, loadingClass) {
      // this.$vs.loading({
      //   background: "#fff",
      //   color: "#626262",
      //   container: `#${selector}`,
      //   scale: 0.45,
      //   text: "removing"
      // });

      let self = this;

      self.userDetail[loadingClass] = self.userDetail[loadingClass].filter(
        (value) => value.photoUrl != imageUrl
      );

      self.userDetail.removedImages.push(imageUrl);
    },

    async saveUserDetails() {
      let self = this;
      let isValid = await this.$validator.validateAll();

      isValid = await this.$validator.validate();

      isValid = await this.$refs.stepTwoRef.$validator.validate();

      isValid = await this.$refs.stepThreeRef.$validator.validate();
      isValid = await this.$refs.step4Ref.$validator.validate();
      isValid = await this.$refs.step5Ref.$validator.validate();
      isValid = await this.$refs.step6Ref.$validator.validate();
      isValid = await this.$refs.step7Ref.$validator.validate();
      isValid = await this.$refs.step8Ref.$validator.validate();
      isValid = await this.$refs.step9Ref.$validator.validate();
      isValid = await this.$refs.step10Ref.$validator.validate();
      isValid = await this.$refs.step11Ref.$validator.validate();

      if (self.userDetail.isResidentialAddress === "") {
        isValid = false;
        this.validationKeys.showIsResidentialAddressError = true;
      } else {
        this.validationKeys.showIsResidentialAddressError = false;
      }

      if (self.userDetail.intendToSecureS4Medication === "") {
        isValid = false;
        this.validationKeys.showIntendToSecureS4MedicationError = true;
      } else {
        this.validationKeys.showIntendToSecureS4MedicationError = false;
      }

      if (self.userDetail.haveAdrenalineAndHyalaseInClinic === "") {
        isValid = false;
        this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = true;
      } else {
        this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = false;
      }

      if (self.userDetail.abnCertificate == "") {
        this.errors.add({
          field: "abnCertificate",
          msg: "ABN Certificate is required",
        });
        document.getElementById("abnCertificateId").style.borderColor =
          "#ea5455";
        isValid = false;
      } else {
        document.getElementById("abnCertificateId").style.borderColor =
          "#cccccc";
        this.errors.remove("abnCertificate");
      }

      if (self.userDetail.clinicFrontPhoto.length === 0) {
        this.$refs.stepThreeRef.errors.add({
          field: "clinicFrontPhoto",
          msg: "Clinic Front Photo is required",
        });
        document.getElementById("clinicFrontPhoto").style.borderColor =
          "#ea5455";
        isValid = false;
      } else {
        document.getElementById("clinicFrontPhoto").style.borderColor =
          "#cccccc";
        this.$refs.stepThreeRef.errors.remove("clinicFrontPhoto");
      }

      if (self.userDetail.clinicFloorPhoto.length === 0) {
        this.$refs.step4Ref.errors.add({
          field: "clinicFloorPhoto",
          msg: "Clinic Floor Photo is required",
        });
        document.getElementById("clinicFloorPhoto").style.borderColor =
          "#ea5455";
        isValid = false;
      } else {
        document.getElementById("clinicFloorPhoto").style.borderColor =
          "#cccccc";
        this.$refs.step4Ref.errors.remove("clinicFloorPhoto");
      }

      if (self.userDetail.clinicTreatmentBedPhoto.length === 0) {
        this.$refs.step5Ref.errors.add({
          field: "clinicTreatmentBedPhoto",
          msg: "Clinic Treatment Bed Photo is required",
        });
        document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
          "#ea5455";
        isValid = false;
      } else {
        document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
          "#cccccc";
        this.$refs.step5Ref.errors.remove("clinicTreatmentBedPhoto");
      }

      if (self.userDetail.clinicDrawingUpAreaPhoto.length === 0) {
        this.$refs.step6Ref.errors.add({
          field: "clinicDrawingUpAreaPhoto",
          msg: "Clinic Drawing Up Area Photo is required",
        });
        document.getElementById("clinicDrawingUpAreaPhoto").style.borderColor =
          "#ea5455";
        isValid = false;
      } else {
        document.getElementById("clinicDrawingUpAreaPhoto").style.borderColor =
          "#cccccc";
        this.$refs.step6Ref.errors.remove("clinicDrawingUpAreaPhoto");
      }

      if (self.userDetail.clinicFridgePhoto.length === 0) {
        this.$refs.step7Ref.errors.add({
          field: "clinicFridgePhoto",
          msg: "Clinic Fridge Photo is required",
        });
        document.getElementById("clinicFridgePhoto").style.borderColor =
          "#ea5455";
        isValid = false;
      } else {
        document.getElementById("clinicFridgePhoto").style.borderColor =
          "#cccccc";
        this.$refs.step7Ref.errors.remove("clinicFridgePhoto");
      }

      if (self.userDetail.clinicDrugStoragePhoto.length === 0) {
        this.$refs.step8Ref.errors.add({
          field: "clinicDrugStoragePhoto",
          msg: "Clinic Drug Storage Photo is required",
        });
        document.getElementById("clinicDrugStoragePhoto").style.borderColor =
          "#ea5455";
        isValid = false;
      } else {
        document.getElementById("clinicDrugStoragePhoto").style.borderColor =
          "#cccccc";
        this.$refs.step8Ref.errors.remove("clinicDrugStoragePhoto");
      }

      if (self.userDetail.clinicBinsPhoto.length === 0) {
        this.$refs.step9Ref.errors.add({
          field: "clinicBinsPhoto",
          msg: "Clinic Bins Photo is required",
        });
        document.getElementById("clinicBinsPhoto").style.borderColor =
          "#ea5455";
        isValid = false;
      } else {
        document.getElementById("clinicBinsPhoto").style.borderColor =
          "#cccccc";
        this.$refs.step9Ref.errors.remove("clinicBinsPhoto");
      }

      if (isValid) {
        let info = this.userDetail;
        if (info.userType === "clinic") {
          if (this.checkDisplayAddress !== this.addressObj.displayAddress) {
            this.errors.add({
              field: "displayAddress",
              msg: "Invalid address. Must choose from suggestion dropdown",
            });
            this.$vs.loading.close();
          } else {
            this.$vs.loading();

            const updateData = {
              userType: info.userType,
              firstName: info.firstName,
              lastName: info.lastName,
              companyName: info.companyName,
              clinicName: info.clinicName,
              abn: info.abn,
              bestContactName: info.bestContactName,
              bestContactEmail: info.bestContactEmail,
              bestContactNumber: info.bestContactNumber,
              invoiceEmail: info.invoiceEmail,
              profileImage: info.profileImage,
              address: this.addressObj,
              postalAddress: info.postalAddress,
              contactNumber: info.contactNumber,
              instagram: info.instagram,
              facebook: info.facebook,
              website: info.website,
              clinicAddress1: info.clinicAddress1,
              isResidentialAddress: info.isResidentialAddress,
              haveAdrenalineAndHyalaseInClinic: info.haveAdrenalineAndHyalaseInClinic,
              intendToSecureS4Medication: info.intendToSecureS4Medication,
              abnCertificate: info.abnCertificate,
              councilPermits: info.councilPermits,
              poisonsCertificate: info.poisonsCertificate,
              clinicOpeningHours: info.clinicOpeningHours,
              clinicFrontPhoto: info.clinicFrontPhoto,
              clinicFloorPhoto: info.clinicFloorPhoto,
              clinicTreatmentBedPhoto: info.clinicTreatmentBedPhoto,
              clinicDrawingUpAreaPhoto: info.clinicDrawingUpAreaPhoto,
              clinicFridgePhoto: info.clinicFridgePhoto,
              clinicDrugStoragePhoto: info.clinicDrugStoragePhoto,
              clinicBinsPhoto: info.clinicBinsPhoto,
              clinicRoomLockPhoto: info.clinicRoomLockPhoto,
              clinicEpipenAndHyalasePhoto: info.clinicEpipenAndHyalasePhoto,
              removedImages: info.removedImages,
              userId: info.id,
              updater: `${this.oldData.firstName} ${this.oldData.lastName}`,
              updatedFields: updatedFieldChecker(this.oldData, updateData)
            };

            this.updateClinicWebProfile(updateData)
              .then((res) => {
                this.$vs.notify({
                  title: "Success",
                  text: "Profile updated successfully !",
                  color: "success",
                });
                this.$emit("cancelEdit");
                this.$vs.loading.close();
              })
              .catch((err) => {
                this.$vs.loading.close();
                this.$vs.notify({
                  title: "Failed",
                  text: err.data.message
                    ? err.data.message
                    : "Failed to update profile. Try again!",
                  color: "danger",
                });

                if (err.status === 417 && err.data.title === "Invalid ABN") {
                  this.errors.add({
                    field: "abn",
                    msg: err.data.message,
                  });
                }

                if (err.status === 422) {
                  this.mapServerErrors(this, err);
                }
              });
          }
        } else {
          this.$vs.loading();

          const updateData = {
            userId: info.id,
            updater: `${this.oldData.firstName} ${this.oldData.lastName}`,
            updatedFields: updatedFieldChecker(this.oldData, updateData),
            firstName: info.firstName,
            lastName: info.lastName,
            profileImage: info.profileImage,
          };
          this.updateAdminProfile(updateData)
            .then((res) => {
              this.$vs.notify({
                title: "Success",
                text: "Profile updated successfully !",
                color: "success",
              });
              this.$emit("cancelEdit");
              this.$vs.loading.close();
            })
            .catch((err) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Failed",
                text: "Failed to update profile. Try again!",
                color: "danger",
              });

              if (err.status === 422) {
                this.mapServerErrors(this, err);
              }
            });
        }
      }
    },
    change(key = "") {
      this.$vs.loading({
        text: "Uploading File...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key + " div div",
      });
    },

    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        if (
          value.context.key === "latitude" ||
          value.context.key === "longitude"
        ) {
          self.errors.add({
            field: "displayAddress",
            msg: "Invalid address. Must choose from suggestion dropdown",
          });
        } else {
          self.errors.add({
            field: value.context.key,
            msg: value.message.replace(/"/g, ""),
          });
        }
      });
    },
    handleError(key = "") {
      this.$vs.loading.close("#" + key + " div div > .con-vs-loading");
      this.$vs.notify({
        title: "Upload Failed",
        text: "Unable to upload file at the moment.",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger",
      });
    },
    changePasswordHandler() {
      let user = this.$store.state.AppActiveUser.userRole;
      if (user === "superAdmin") {
        this.$router.push("/super-admin/change-password");
      } else {
        this.$router.push("/" + user + "/change-password");
      }
    },
    assingUserDetailData(userData) {
      let self = this;
      this.userDetail.id = userData.uid;
      this.userDetail.firstName = userData.firstName || "";
      this.userDetail.lastName = userData.lastName || "";
      this.userDetail.userType = userData.userType || "";
      this.userDetail.profileImage = userData.profileImage || "";

      this.userDetail.address = userData.address
        ? userData.address
        : {
            displayAddress: "",
            state: "",
            suburb: "",
            postCode: "",
            geometry: { type: "Point", coordinates: [] },
          };
      this.userDetail.companyName = userData.companyName || "";
      this.userDetail.clinicName = userData.clinicName || "";

      this.userDetail.abn = userData.abn || "";
      this.userDetail.postalAddress = userData.postalAddress || "";
      this.userDetail.bestContactName = userData.bestContactName || "";
      this.userDetail.bestContactEmail = userData.bestContactEmail || "";
      this.userDetail.bestContactNumber = userData.bestContactNumber || "";
      this.userDetail.billingEmail = userData.billingEmail || "";
      this.userDetail.invoiceEmail = userData.invoiceEmail || "";

      this.userDetail.userAddressObject = userData.address;

      this.addressObj = {
        displayAddress: userData.address ? userData.address.displayAddress : "",
        suburb: userData.address ? userData.address.suburb : "",
        state: userData.address ? userData.address.state : "",
        postCode: userData.address ? userData.address.postCode : "",
        coordinates:
          userData.address &&
          userData.address.geometry &&
          userData.address.geometry.coordinates
            ? {
                latitude: userData.address.geometry.coordinates[1],
                longitude: userData.address.geometry.coordinates[0],
              }
            : { latitude: null, longitude: null },
      };
      this.checkDisplayAddress = userData.address
        ? userData.address.displayAddress
        : "";

      self.userDetail.contactNumber = userData.contactNumber
        ? userData.contactNumber
        : "";
      self.userDetail.instagram = userData.instagram ? userData.instagram : "";
      self.userDetail.facebook = userData.facebook ? userData.facebook : "";
      self.userDetail.website = userData.website ? userData.website : "";
      self.userDetail.clinicAddress1 = userData.clinicAddress1
        ? userData.clinicAddress1
        : "";
      self.userDetail.isResidentialAddress = userData.isResidentialAddress
        ? userData.isResidentialAddress
        : "";
      self.userDetail.haveAdrenalineAndHyalaseInClinic =
        userData.haveAdrenalineAndHyalaseInClinic
          ? userData.haveAdrenalineAndHyalaseInClinic
          : "";
      self.userDetail.intendToSecureS4Medication =
        userData.intendToSecureS4Medication
          ? userData.intendToSecureS4Medication
          : "";
      self.userDetail.abnCertificate = userData.abnCertificate
        ? userData.abnCertificate
        : "";
      self.userDetail.councilPermits = userData.councilPermits
        ? userData.councilPermits
        : [];
      self.userDetail.poisonsCertificate = userData.poisonsCertificate
        ? userData.poisonsCertificate
        : [];
      self.userDetail.clinicOpeningHours = userData.clinicOpeningHours
        ? userData.clinicOpeningHours
        : {
            mondays: "",
            tuesdays: "",
            wednesdays: "",
            thursdays: "",
            fridays: "",
            saturdays: "",
            sundays: "",
          };
      self.userDetail.clinicFrontPhoto = userData.clinicFrontPhoto
        ? userData.clinicFrontPhoto
        : [];
      self.userDetail.clinicFloorPhoto = userData.clinicFloorPhoto
        ? userData.clinicFloorPhoto
        : [];
      self.userDetail.clinicTreatmentBedPhoto = userData.clinicTreatmentBedPhoto
        ? userData.clinicTreatmentBedPhoto
        : [];
      self.userDetail.clinicDrawingUpAreaPhoto =
        userData.clinicDrawingUpAreaPhoto
          ? userData.clinicDrawingUpAreaPhoto
          : [];
      self.userDetail.clinicFridgePhoto = userData.clinicFridgePhoto
        ? userData.clinicFridgePhoto
        : [];
      self.userDetail.clinicDrugStoragePhoto = userData.clinicDrugStoragePhoto
        ? userData.clinicDrugStoragePhoto
        : [];
      self.userDetail.clinicBinsPhoto = userData.clinicBinsPhoto
        ? userData.clinicBinsPhoto
        : [];
      self.userDetail.clinicRoomLockPhoto = userData.clinicRoomLockPhoto
        ? userData.clinicRoomLockPhoto
        : [];
      self.userDetail.clinicEpipenAndHyalasePhoto =
        userData.clinicEpipenAndHyalasePhoto
          ? userData.clinicEpipenAndHyalasePhoto
          : [];

      self.oldData = this.userDetail
    },
    uploadImage(event, key = "profileImage", loadingClass = "") {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };

      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "abnCertificateUpload") {
            document.getElementById("abnCertificateId").style.borderColor =
              "#cccccc";
            self.userDetail.abnCertificate = res.data.Location;
            this.errors.remove("abnCertificate");
          }

          if (key === "profileImageUpload") {
            self.userDetail.profileImage = res.data.Location;
            this.errors.remove("profileImage");
          }

          if (key === "aphraUpload") {
            self.userDetail.ahpraCertificate = res.data.Location;
          }
          if (key === "insuranceUpload") {
            self.userDetail.insuranceCertificate = res.data.Location;
          }
          if (key === "signature") {
            self.userDetail.signature = res.data.Location;
          }
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.response.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    initilizeAutoComplete(element) {
      this.autocomplete = new google.maps.places.Autocomplete(element, {
        types: ["geocode"],
        componentRestrictions: { country: "au" },
      });
      this.autocomplete.addListener("place_changed", () => {
        let place = this.autocomplete.getPlace();
        this.errors.remove("displayAddress");
        let ac = place.address_components;
        this.checkDisplayAddress = place.formatted_address;
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();
        this.addressObj.displayAddress = place.formatted_address;
        this.addressObj.coordinates.longitude = lon;
        this.addressObj.coordinates.latitude = lat;

        let self = this;

        self.addressObj.suburb = "";
        self.addressObj.state = "";
        self.addressObj.postCode = "";
        if (
          place &&
          place.address_components &&
          place.address_components.length > 0
        ) {
          place.address_components.forEach(function (gAdd) {
            if (gAdd.types[0] === "administrative_area_level_2") {
              self.addressObj.suburb = gAdd.long_name;
              self.errors.remove("clinicSuburb");
            }
            if (gAdd.types[0] === "administrative_area_level_1") {
              self.addressObj.state = gAdd.long_name;
              self.errors.remove("clinicState");
            }
            if (gAdd.types[0] === "postal_code") {
              self.addressObj.postCode = gAdd.long_name;
              self.errors.remove("clinicPostCode");
            }
          });
        }
      });
    },
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  created() {
    this.updater = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_PROCESS_TOKEN}User`)
    );

    this.addressItems = [];
    this.$vs.loading();
    this.$store
      .dispatch(
        "general/getUserDetailForProfile",
        this.$store.state.AppActiveUser._id
      )
      .then((response) => {
        this.fetchedData = response.data.data;
        this.assingUserDetailData(this.fetchedData);
        return true;
      })
      .then((response) => {
        this.$vs.loading.close();

        let element = this.$refs.googleAutocomplete;
        this.initilizeAutoComplete(element);
      })
      .catch((err) => {
        this.$vs.loading.close();
      });
  },
  watch: {
    "userDetail.isResidentialAddress": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showIsResidentialAddressError = false;
      }
    },
    "userDetail.intendToSecureS4Medication": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showIntendToSecureS4MedicationError = false;
      }
    },
    "userDetail.haveAdrenalineAndHyalaseInClinic": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showHaveAdrenalineAndHyalaseInClinicError = false;
      }
    },
  },
};
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
.fileContainer {
  overflow: hidden;
  position: relative;
  margin-top: 65px;
}

.fileContainer [type="file"] {
  cursor: pointer;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
.image-upload {
  cursor: pointer;
}

.cardDiv {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border: 1px solid #3dc9b3;
  margin-top: 40px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  color: #3dc9b3;
}

.con-input-upload.disabled-upload {
  display: none;
}

.list-group {
  border: 1px solid #ddd;
  z-index: 99999;
  position: absolute;
  background: #fff;
}

.list-group li {
  padding: 5px 10px;
  cursor: pointer;
}

.vs-select-primary ul:last-child {
  display: none;
}

.con-input-upload {
  height: 100px;
  width: auto;
}

div.img-upload {
  height: 100px !important;
  width: auto !important;
}

div.img-upload img {
  height: 100% !important;
  width: 100% !important;
}

.error-text {
  font-size: 0.65rem !important;
  padding: 2px 4px 4px;
  display: block;
  margin-top: -6px;
}

.no-margin-span {
  margin-top: 0 !important;
}

.clinicOpeningHoursDiv {
  width: 100%;
  min-height: 75px;
  border: 2px solid #ddd;
  border-radius: 10px;
  position: relative;
  margin-top: 10px;
}

.clinicOpeningHoursDiv span {
  font-size: 15px;
  font-weight: 600;
  background: #fff;
  position: absolute;
  top: -12px;
  left: 35px;
  padding: 0 8px;
}

.uploadedImages {
  text-align: left !important;
}

@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
